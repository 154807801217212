<template>
    <div>
        <p class="main-title">Artworks</p>
    </div>
    
    <div class="container">

        <div class="card-container">
            <p class="title"> // Vuelta por un universo <br>descentralizado.</p>
            <VueCanvas />
            <p class="details"> > Detalle</p>
            <p class="details-info">
                La cantidad de estrellas dependen del precio del ETH y la velocidad
                con la que se mueven del precio del gas de la red.
            </p>
        </div>
        <!-- <div class="card-container">
            <p class="title"> // Sweet sahumerio.</p>
            <Pool />
            <p class="details"> > Detalle</p>
            <p class="details-info">
                Cambia de color segun la hora.
            </p>
        </div> -->
        <div class="card-container">
            <p class="title"> // Le voyage dans la lune.</p>
            <div class="img-not-available">
                <p class="not-available">Not available.</p>
            </div>
            <p class="details"> > Detalle</p>
            <p class="details-info">
                Visible los días de luna llena.
            </p>
            <!-- <p class="details-info">
                The amount of stars depends on the ETH price and the speed 
                with which they move on the network price gas.
            </p> -->
        </div>
    </div>
</template>

<script>
import ArtworkCard from '@/components/ArtworkCard.vue';
import Pool from '@/components/Pool.vue';
import VueCanvas from "@/components/StarsP5/Canvas.vue";

export default {
    name: 'Home',
    components: {
        ArtworkCard,
        Pool,
        VueCanvas
    }
}
</script>

<style>

.main-title {
    margin-bottom: 0px !important;
}

.container {
    margin: 20px auto 0 auto;
    padding-bottom: 20px;
}

.card-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.card-container a {
    text-decoration: none;
    color: white;
}

.img {
    width: 300px;
    height: 350px;
    margin: 15px auto 0 auto;

    object-fit: cover;
    overflow: hidden;
}
.title {
    margin: 20px auto 0 auto;
    width: 80%;
    text-align: left;
}

.details {
    font-size: 12px;
    margin: 15px auto 0 auto;
    width: 80%;
    text-align: left;
}

.details-info {
    font-size: 12px;
    margin: 5px auto;
    width: 80%;
    text-align: left;
}

.img-not-available {
    width: 300px;
    height: 350px;
    margin: 15px auto 0 auto;
    border: 1px solid white;
    object-fit: cover;
    overflow: hidden;
}

.not-available {
    margin: 145px auto;
    font-size: 12px;
    width: 60%;
}

</style>
