<template>
    <div class="card-container">
        <!-- <img v-if="showNumber >= 00 && showNumber <= 159" class="img" src="../assets/pool/codePool1.jpg" alt="">
        <img v-if="showNumber >= 00 && showNumber <= 159" class="img" src="../assets/pool/codePool2.jpg" alt="">
        <img v-if="showNumber >= 00 && showNumber <= 159" class="img" src="../assets/pool/codePool3.jpg" alt="">
        <img v-if="showNumber >= 00 && showNumber <= 159" class="img" src="../assets/pool/codePool4.jpg" alt="">
        <img v-if="showNumber >= 00 && showNumber <= 159" class="img" src="../assets/pool/codePool5.jpg" alt="">
        <img v-if="showNumber >= 00 && showNumber <= 159" class="img" src="../assets/pool/codePool6.jpg" alt="">
        <img v-if="showNumber >= 00 && showNumber <= 159" class="img" src="../assets/pool/codePool7.jpg" alt="">
        <img v-if="showNumber >= 00 && showNumber <= 159" class="img" src="../assets/pool/codePool8.jpg" alt="">
        <img v-if="showNumber >= 00 && showNumber <= 159" class="img" src="../assets/pool/codePool9.jpg" alt="">
        <img v-if="showNumber >= 00 && showNumber <= 159" class="img" src="../assets/pool/codePool10.jpg" alt="">

        <img v-if="showNumber >= 00 && showNumber <= 159" class="img" src="../assets/pool/codePool11.jpg" alt="">
        <img v-if="showNumber >= 00 && showNumber <= 159" class="img" src="../assets/pool/codePool12.jpg" alt="">
        <img v-if="showNumber >= 00 && showNumber <= 159" class="img" src="../assets/pool/codePool13.jpg" alt="">
        <img v-if="showNumber >= 00 && showNumber <= 159" class="img" src="../assets/pool/codePool14.jpg" alt="">
        <img v-if="showNumber >= 00 && showNumber <= 159" class="img" src="../assets/pool/codePool15.jpg" alt="">
        <img v-if="showNumber >= 00 && showNumber <= 159" class="img" src="../assets/pool/codePool16.jpg" alt="">
        <img v-if="showNumber >= 00 && showNumber <= 159" class="img" src="../assets/pool/codePool17.jpg" alt="">
        <img v-if="showNumber >= 00 && showNumber <= 159" class="img" src="../assets/pool/codePool18.jpg" alt="">
        <img v-if="showNumber >= 00 && showNumber <= 159" class="img" src="../assets/pool/codePool19.jpg" alt="">
        <img v-if="showNumber >= 00 && showNumber <= 159" class="img" src="../assets/pool/codePool20.jpg" alt=""> -->

        <img v-if="showNumber === 21" class="img" src="../assets/pool/codePool21.jpg" alt="">
        <img v-if="showNumber === 22" class="img" src="../assets/pool/codePool22.jpg" alt="">
        <img v-if="showNumber === 23" class="img" src="../assets/pool/codePool23.jpg" alt="">
        <img v-if="showNumber === 24" class="img" src="../assets/pool/codePool24.jpg" alt="">
        <img v-if="showNumber === 25" class="img" src="../assets/pool/codePool25.jpg" alt="">
        <img v-if="showNumber === 26" class="img" src="../assets/pool/codePool26.jpg" alt="">
        <img v-if="showNumber === 27" class="img" src="../assets/pool/codePool27.jpg" alt="">
        <img v-if="showNumber === 28" class="img" src="../assets/pool/codePool28.jpg" alt="">
        <img v-if="showNumber === 29" class="img" src="../assets/pool/codePool29.jpg" alt="">
        <img v-if="showNumber === 30" class="img" src="../assets/pool/codePool30.jpg" alt="">

        <img v-if="showNumber === 31" class="img" src="../assets/pool/codePool31.jpg" alt="">
        <img v-if="showNumber === 32" class="img" src="../assets/pool/codePool32.jpg" alt="">
        <img v-if="showNumber === 33" class="img" src="../assets/pool/codePool33.jpg" alt="">
        <img v-if="showNumber === 34" class="img" src="../assets/pool/codePool34.jpg" alt="">
        <img v-if="showNumber === 35" class="img" src="../assets/pool/codePool35.jpg" alt="">
        <img v-if="showNumber === 36" class="img" src="../assets/pool/codePool36.jpg" alt="">
        <img v-if="showNumber === 37" class="img" src="../assets/pool/codePool37.jpg" alt="">
        <img v-if="showNumber === 38" class="img" src="../assets/pool/codePool38.jpg" alt="">
        <img v-if="showNumber === 39" class="img" src="../assets/pool/codePool39.jpg" alt="">
        <img v-if="showNumber === 40" class="img" src="../assets/pool/codePool40.jpg" alt="">

        <img v-if="showNumber === 41" class="img" src="../assets/pool/codePool41.jpg" alt="">
        <img v-if="showNumber === 42" class="img" src="../assets/pool/codePool42.jpg" alt="">
        <img v-if="showNumber === 43" class="img" src="../assets/pool/codePool43.jpg" alt="">
        <img v-if="showNumber === 44" class="img" src="../assets/pool/codePool44.jpg" alt="">
        <img v-if="showNumber === 45" class="img" src="../assets/pool/codePool45.jpg" alt="">
        <img v-if="showNumber === 46" class="img" src="../assets/pool/codePool46.jpg" alt="">
        <img v-if="showNumber === 47" class="img" src="../assets/pool/codePool47.jpg" alt="">
        <img v-if="showNumber === 48" class="img" src="../assets/pool/codePool48.jpg" alt="">
        <img v-if="showNumber === 49" class="img" src="../assets/pool/codePool49.jpg" alt="">
        <img v-if="showNumber === 50" class="img" src="../assets/pool/codePool50.jpg" alt="">

        <img v-if="showNumber === 51" class="img" src="../assets/pool/codePool51.jpg" alt="">
        <img v-if="showNumber === 52" class="img" src="../assets/pool/codePool52.jpg" alt="">
        <img v-if="showNumber === 53" class="img" src="../assets/pool/codePool53.jpg" alt="">
        <img v-if="showNumber === 54" class="img" src="../assets/pool/codePool54.jpg" alt="">
        <img v-if="showNumber === 55" class="img" src="../assets/pool/codePool55.jpg" alt="">
        <img v-if="showNumber === 56" class="img" src="../assets/pool/codePool56.jpg" alt="">
        <img v-if="showNumber === 57" class="img" src="../assets/pool/codePool57.jpg" alt="">
        <img v-if="showNumber === 58" class="img" src="../assets/pool/codePool58.jpg" alt="">
        <img v-if="showNumber === 59" class="img" src="../assets/pool/codePool59.jpg" alt="">
        <img v-if="showNumber === 60" class="img" src="../assets/pool/codePool60.jpg" alt="">

        <img v-if="showNumber === 61" class="img" src="../assets/pool/codePool61.jpg" alt="">
        <img v-if="showNumber === 62" class="img" src="../assets/pool/codePool62.jpg" alt="">
        <img v-if="showNumber === 63" class="img" src="../assets/pool/codePool63.jpg" alt="">
        <img v-if="showNumber === 64" class="img" src="../assets/pool/codePool64.jpg" alt="">
        <img v-if="showNumber === 65" class="img" src="../assets/pool/codePool65.jpg" alt="">
        <img v-if="showNumber === 66" class="img" src="../assets/pool/codePool66.jpg" alt="">
        <img v-if="showNumber === 67" class="img" src="../assets/pool/codePool67.jpg" alt="">
        <img v-if="showNumber === 68" class="img" src="../assets/pool/codePool68.jpg" alt="">
        <img v-if="showNumber === 69" class="img" src="../assets/pool/codePool69.jpg" alt="">
        <img v-if="showNumber === 70" class="img" src="../assets/pool/codePool70.jpg" alt="">

        <img v-if="showNumber === 71" class="img" src="../assets/pool/codePool71.jpg" alt="">
        <img v-if="showNumber === 72" class="img" src="../assets/pool/codePool72.jpg" alt="">
        <img v-if="showNumber === 73" class="img" src="../assets/pool/codePool73.jpg" alt="">
        <img v-if="showNumber === 74" class="img" src="../assets/pool/codePool74.jpg" alt="">
        <img v-if="showNumber === 75" class="img" src="../assets/pool/codePool75.jpg" alt="">
        <img v-if="showNumber === 76" class="img" src="../assets/pool/codePool76.jpg" alt="">
        <img v-if="showNumber === 77" class="img" src="../assets/pool/codePool77.jpg" alt="">
        <img v-if="showNumber === 78" class="img" src="../assets/pool/codePool78.jpg" alt="">
        <img v-if="showNumber === 79" class="img" src="../assets/pool/codePool79.jpg" alt="">
        <img v-if="showNumber === 80" class="img" src="../assets/pool/codePool80.jpg" alt="">

        <img v-if="showNumber === 81" class="img" src="../assets/pool/codePool81.jpg" alt="">
        <img v-if="showNumber === 82" class="img" src="../assets/pool/codePool82.jpg" alt="">
        <img v-if="showNumber === 83" class="img" src="../assets/pool/codePool83.jpg" alt="">
        <img v-if="showNumber === 84" class="img" src="../assets/pool/codePool84.jpg" alt="">
        <img v-if="showNumber === 85" class="img" src="../assets/pool/codePool85.jpg" alt="">
        <img v-if="showNumber === 86" class="img" src="../assets/pool/codePool86.jpg" alt="">
        <img v-if="showNumber === 87" class="img" src="../assets/pool/codePool87.jpg" alt="">
        <img v-if="showNumber === 88" class="img" src="../assets/pool/codePool88.jpg" alt="">
        <img v-if="showNumber === 89" class="img" src="../assets/pool/codePool89.jpg" alt="">
        <img v-if="showNumber === 90" class="img" src="../assets/pool/codePool90.jpg" alt="">

        <img v-if="showNumber === 91" class="img" src="../assets/pool/codePool91.jpg" alt="">
        <img v-if="showNumber === 92" class="img" src="../assets/pool/codePool92.jpg" alt="">
        <img v-if="showNumber === 93" class="img" src="../assets/pool/codePool93.jpg" alt="">
        <img v-if="showNumber === 94" class="img" src="../assets/pool/codePool94.jpg" alt="">
        <img v-if="showNumber === 95" class="img" src="../assets/pool/codePool95.jpg" alt="">
        <img v-if="showNumber === 96" class="img" src="../assets/pool/codePool96.jpg" alt="">
        <img v-if="showNumber === 97" class="img" src="../assets/pool/codePool97.jpg" alt="">
        <img v-if="showNumber === 98" class="img" src="../assets/pool/codePool98.jpg" alt="">
        <img v-if="showNumber === 99" class="img" src="../assets/pool/codePool99.jpg" alt="">
        <img v-if="showNumber === 100" class="img" src="../assets/pool/codePool100.jpg" alt="">

        <img v-if="showNumber === 101" class="img" src="../assets/pool/codePool101.jpg" alt="">
        <img v-if="showNumber === 102" class="img" src="../assets/pool/codePool102.jpg" alt="">
        <img v-if="showNumber === 103" class="img" src="../assets/pool/codePool103.jpg" alt="">
        <img v-if="showNumber === 104" class="img" src="../assets/pool/codePool104.jpg" alt="">
        <img v-if="showNumber === 105" class="img" src="../assets/pool/codePool105.jpg" alt="">
        <img v-if="showNumber === 106" class="img" src="../assets/pool/codePool106.jpg" alt="">
        <img v-if="showNumber === 107" class="img" src="../assets/pool/codePool107.jpg" alt="">
        <img v-if="showNumber === 108" class="img" src="../assets/pool/codePool108.jpg" alt="">
        <img v-if="showNumber === 109" class="img" src="../assets/pool/codePool109.jpg" alt="">
        <img v-if="showNumber === 110" class="img" src="../assets/pool/codePool110.jpg" alt="">

        <img v-if="showNumber === 111" class="img" src="../assets/pool/codePool111.jpg" alt="">
        <img v-if="showNumber === 112" class="img" src="../assets/pool/codePool112.jpg" alt="">
        <img v-if="showNumber === 113" class="img" src="../assets/pool/codePool113.jpg" alt="">
        <img v-if="showNumber === 114" class="img" src="../assets/pool/codePool114.jpg" alt="">
        <img v-if="showNumber === 115" class="img" src="../assets/pool/codePool115.jpg" alt="">
        <img v-if="showNumber === 116" class="img" src="../assets/pool/codePool116.jpg" alt="">
        <img v-if="showNumber === 117" class="img" src="../assets/pool/codePool117.jpg" alt="">
        <img v-if="showNumber === 118" class="img" src="../assets/pool/codePool118.jpg" alt="">
        <img v-if="showNumber === 119" class="img" src="../assets/pool/codePool119.jpg" alt="">
        <img v-if="showNumber === 120" class="img" src="../assets/pool/codePool120.jpg" alt="">

        <img v-if="showNumber === 121" class="img" src="../assets/pool/codePool121.jpg" alt="">
        <img v-if="showNumber === 122" class="img" src="../assets/pool/codePool122.jpg" alt="">
        <img v-if="showNumber === 123" class="img" src="../assets/pool/codePool123.jpg" alt="">
        <img v-if="showNumber === 124" class="img" src="../assets/pool/codePool124.jpg" alt="">
        <img v-if="showNumber === 125" class="img" src="../assets/pool/codePool125.jpg" alt="">
        <img v-if="showNumber === 126" class="img" src="../assets/pool/codePool126.jpg" alt="">
        <img v-if="showNumber === 127" class="img" src="../assets/pool/codePool127.jpg" alt="">
        <img v-if="showNumber === 128" class="img" src="../assets/pool/codePool128.jpg" alt="">
        <img v-if="showNumber === 129" class="img" src="../assets/pool/codePool129.jpg" alt="">
        <img v-if="showNumber === 130" class="img" src="../assets/pool/codePool130.jpg" alt="">

        <img v-if="showNumber === 131" class="img" src="../assets/pool/codePool131.jpg" alt="">
        <img v-if="showNumber === 132" class="img" src="../assets/pool/codePool132.jpg" alt="">
        <img v-if="showNumber === 133" class="img" src="../assets/pool/codePool133.jpg" alt="">
        <img v-if="showNumber === 134" class="img" src="../assets/pool/codePool134.jpg" alt="">
        <img v-if="showNumber === 135" class="img" src="../assets/pool/codePool135.jpg" alt="">
        <img v-if="showNumber === 136" class="img" src="../assets/pool/codePool136.jpg" alt="">
        <img v-if="showNumber === 137" class="img" src="../assets/pool/codePool137.jpg" alt="">
        <img v-if="showNumber === 138" class="img" src="../assets/pool/codePool138.jpg" alt="">
        <img v-if="showNumber === 139" class="img" src="../assets/pool/codePool139.jpg" alt="">
        <img v-if="showNumber === 140" class="img" src="../assets/pool/codePool140.jpg" alt="">

        <img v-if="showNumber === 141" class="img" src="../assets/pool/codePool141.jpg" alt="">
        <img v-if="showNumber === 142" class="img" src="../assets/pool/codePool142.jpg" alt="">
        <img v-if="showNumber === 143" class="img" src="../assets/pool/codePool143.jpg" alt="">
        <img v-if="showNumber === 144" class="img" src="../assets/pool/codePool144.jpg" alt="">
        <img v-if="showNumber === 145" class="img" src="../assets/pool/codePool145.jpg" alt="">
        <img v-if="showNumber === 146" class="img" src="../assets/pool/codePool146.jpg" alt="">
        <img v-if="showNumber === 147" class="img" src="../assets/pool/codePool147.jpg" alt="">
        <img v-if="showNumber === 148" class="img" src="../assets/pool/codePool148.jpg" alt="">
        <img v-if="showNumber === 149" class="img" src="../assets/pool/codePool149.jpg" alt="">
        <img v-if="showNumber === 150" class="img" src="../assets/pool/codePool150.jpg" alt="">

        <img v-if="showNumber === 151" class="img" src="../assets/pool/codePool151.jpg" alt="">
        <img v-if="showNumber === 152" class="img" src="../assets/pool/codePool152.jpg" alt="">
        <img v-if="showNumber === 153" class="img" src="../assets/pool/codePool153.jpg" alt="">
        <img v-if="showNumber === 154" class="img" src="../assets/pool/codePool154.jpg" alt="">
        <img v-if="showNumber === 155" class="img" src="../assets/pool/codePool155.jpg" alt="">
        <img v-if="showNumber === 156" class="img" src="../assets/pool/codePool156.jpg" alt="">
        <img v-if="showNumber === 157" class="img" src="../assets/pool/codePool157.jpg" alt="">
        <img v-if="showNumber === 158" class="img" src="../assets/pool/codePool158.jpg" alt="">
        <img v-if="showNumber === 159" class="img" src="../assets/pool/codePool159.jpg" alt="">
        <img v-if="showNumber === 160" class="img" src="../assets/pool/codePool160.jpg" alt="">

        <img v-if="showNumber === 161" class="img" src="../assets/pool/codePool161.jpg" alt="">
        <img v-if="showNumber === 162" class="img" src="../assets/pool/codePool162.jpg" alt="">
        <img v-if="showNumber === 163" class="img" src="../assets/pool/codePool163.jpg" alt="">
        <img v-if="showNumber === 164" class="img" src="../assets/pool/codePool164.jpg" alt="">
        <img v-if="showNumber === 165" class="img" src="../assets/pool/codePool165.jpg" alt="">
        <img v-if="showNumber === 166" class="img" src="../assets/pool/codePool166.jpg" alt="">
        <img v-if="showNumber === 167" class="img" src="../assets/pool/codePool167.jpg" alt="">
        <img v-if="showNumber === 168" class="img" src="../assets/pool/codePool168.jpg" alt="">
        <img v-if="showNumber === 169" class="img" src="../assets/pool/codePool169.jpg" alt="">
        <img v-if="showNumber === 170" class="img" src="../assets/pool/codePool170.jpg" alt="">

        <img v-if="showNumber === 171" class="img" src="../assets/pool/codePool171.jpg" alt="">
        <img v-if="showNumber === 172" class="img" src="../assets/pool/codePool172.jpg" alt="">
        <img v-if="showNumber === 173" class="img" src="../assets/pool/codePool173.jpg" alt="">
        <img v-if="showNumber === 174" class="img" src="../assets/pool/codePool174.jpg" alt="">
        <img v-if="showNumber === 175" class="img" src="../assets/pool/codePool175.jpg" alt="">
        <img v-if="showNumber === 176" class="img" src="../assets/pool/codePool176.jpg" alt="">
        <img v-if="showNumber === 177" class="img" src="../assets/pool/codePool177.jpg" alt="">
        <img v-if="showNumber === 178" class="img" src="../assets/pool/codePool178.jpg" alt="">
        <img v-if="showNumber === 179" class="img" src="../assets/pool/codePool179.jpg" alt="">
        <img v-if="showNumber === 180" class="img" src="../assets/pool/codePool180.jpg" alt="">

        <!-- <div v-else class="img2">
            <p class="not-available">Visible entre las <br> 10hs y las 18hs</p>
        </div> -->

        <!-- <p class="details"> > Detalle</p>
        <p class="details-info">
            Cambia de color segun la hora.
        </p> -->
    </div>
</template>

<script>
import { watchEffect, ref } from 'vue';
import ArtworkCard from '@/components/ArtworkCard.vue';
import Pool from '@/components/Pool.vue';

export default {
    name: 'Home',
    components: {
        ArtworkCard,
        Pool
    },

    setup() {
        let showNumber = 1;
        let date = 0;

        function getDate() {
            date = new Date();
            date = Number(date.getHours().toString() + date.getMinutes().toString());
            showNumber = date;
            console.log(date)
            return date;
        }

        getDate();
        console.log(showNumber);

        return {
            showNumber,
        }
    }
}
</script>

<style>
.card-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}

.card-container a {
    text-decoration: none;
    color: white;
}

.img {
    width: 300px;
    height: 350px;
    margin: 15px auto 0 auto;

    object-fit: cover;
    overflow: hidden;
}

.img2 {
    width: 300px;
    height: 350px;
    margin: 15px auto 0 auto;
    border: 1px solid white;
    object-fit: cover;
    overflow: hidden;
}

.not-available {
    margin: 145px auto;
    font-size: 12px;
    width: 60%;
}

.title {
    margin: 20px auto 0 auto;
    width: 80%;
    text-align: left;
}

.details {
    font-size: 12px;
    margin: 15px auto 0 auto;
    width: 80%;
    text-align: left;
}

.details-info {
    font-size: 12px;
    margin: 5px auto;
    width: 80%;
    text-align: left;
}
</style>