<template>
    <div class="card">
        <!-- <img class="img" src="../assets/stars.jpeg" alt=""> -->
    </div>
</template>

<script>

</script>

<style>
.card {
    width: 300px;
    height: 350px;
    border-radius: 10px;
    border: 1px solid white;
    object-fit: cover;
    overflow: hidden;
    margin: 0 auto;
}

.img {
    max-width: 100%;
}
</style>