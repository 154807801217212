<template>
  <div id="vue-canvas"></div>
</template>

<script>
import p52 from 'p5';

export default {
  name: "VueCanvas",
  mounted() {
    
    var width = 400;
    var height = 400;

    const script = function(p5) {

      //ACA TENGO EL OBJETO ESTRELLA COMO FUNCION, no se bien porque. 
        function Star() {
        this.x = p5.random(width, width);
        this.y = p5.random(height, height);
        this.z = p5.random(width);
        this.pz = this.z;
      
        this.update = function() {
          this.z = this.z - speed;
          if (this.z < 1) {
            this.z = width;
            this.x = p5.random(-width, width);
            this.y = p5.random(-height, height);
            this.pz = this.z;
          }
        };
      
        this.show = function() {
          // fill(255);
          p5.strokeWeight(0.5);
          p5.noStroke();
      
          var sx = p5.map(this.x / this.z, 0, 1, 0, width);
          var sy = p5.map(this.y / this.z, 0, 1, 0, height);
      
          var r = p5.map(this.z, 0, width, 0, 0);
          p5.circle(sx, sy, 1);
      
          var px = p5.map(this.x / this.pz, 0, 1, 0, width);
          var py = p5.map(this.y / this.pz, 0, 1, 0, height);
      
          this.pz = this.z;
      
          p5.stroke(255);
          p5.line(px, py, sx, sy);
        };
      }

      var speed = 2;
      let stars = [];

      let fondo;
      let auto;

      fondo = p5.loadImage(require('../../assets/fondoEstrellas.png'));
      auto = p5.loadImage(require('../../assets/autoEstrellas.png'));

      // El SETUP de p5
      p5.setup = () => {
        const canvas = p5.createCanvas(585, 844);
        canvas.parent("vue-canvas");

        for (let i = 0; i < 2400; i++) {
          stars[i] = new Star();
        }
      };

      // El DRAW de p5
      p5.draw = () => {
        speed = 10;

        p5.background(0)
        p5.image(fondo, 0, 0);
        p5.translate(585 / 2, 844 / 2);

        for (let i = 0; i < stars.length; i++) {
          stars[i].update();
          stars[i].show();
        }
        p5.translate(0,0);
        p5.image(auto, -auto.width/2, -auto.height/2);
      };
    };

    // Instanciar P5
    const P5 = require("p5");
    new P5(script);
  }
};
</script>

<style scoped>
#vue-canvas {
  display: block;
  margin: 10px auto;
  padding: 0;
  width: 585px;
  height: 844px;
  overflow: hidden;
}

</style>
